import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";

interface TasksData {
    total: number;
    pending: number;
    inprogress: number;
    completed: number;
    notStarted: number;
}

interface TaskData {
    id: string;
    updatedOn: string;
    status: string;
    percentage: number;
    description: string;
    title: string;
}

interface TaskActivityResData {
  title: string;
  description: string;
  status: boolean;
  id: string;
  updated_at: string;
}

interface TaskDataRes {
  id: string;
  attributes: {
    title: string;
    description: string;
    status: string;
    updated_at: string;
    task_activities: TaskActivityResData[]
  }
}

interface TasksDataRes {
  data: TaskDataRes[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string;
  roleType: string;
  tasks: TasksData;
  selectedTab: string;
  selectedTabLabel: string;
  searchText: string;
  taskData: TaskData[];
  sortBy: string;
  sortLabel: string;
  expanded: boolean;
  isMobile: boolean;
  searchError: string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllTasksDetailApiId: any;

  statusData = [{
    label: 'All Tasks',
    value: 'AllTasks',
  },{
    label: 'Pending',
    value: 'pending',
  },{
    label: 'Inprogress',
    value: 'in_progress',
  },{
    label: 'Completed',
    value: 'completed'
  },{
    label: 'Not Started',
    value: 'not_started',
  }]
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      roleType: "",
      sortLabel: "",
      searchError:"",
      tasks: {
        total: 0,
        pending: 0,
        inprogress: 0,
        completed: 0,
        notStarted: 0
      },
      selectedTab: "AllTasks",
      selectedTabLabel: configJSON.taskListText,
      searchText: "",
      taskData: [],
      sortBy: "",
      expanded: window.innerWidth >= 768,
      isMobile: window.innerWidth < 768,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let webResponseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (webResponseJson && !webResponseJson.errors) {
          if(apiRequestCallId === this.getAllTasksDetailApiId){
            this.handleGetTaskData(webResponseJson);
          }
        }else{
          const tokenErrorMessage = webResponseJson?.errors && webResponseJson?.errors[0]?.token;
          if(tokenErrorMessage && tokenErrorMessage === "Token has Expired"){
            this.goToLoginPage();
          }
        }
      }
      // Customizable Area End
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData('authToken');
    let roleType = await getStorageData('profileData');
    this.setState({ token, roleType: JSON.parse(roleType).role_type });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if((prevState.token !== this.state.token && this.state.token) || 
        prevState.searchText !== this.state.searchText || 
        prevState.selectedTab !== this.state.selectedTab || 
        prevState.sortBy !== this.state.sortBy
      ){
      this.getAllTaskData();
    }
  }

  handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchError: "" }); // Add this line
    const searchText = event.target.value;
    const taskExists = this.state.taskData.some(task => task.title.toLowerCase().includes(searchText.toLowerCase()));
    if (!taskExists) {
      this.setState({ searchError: "No results found" });
    }
    this.setState({ searchText });
  }


  handleFocus = () => {
    if (this.state.isMobile) {
      this.setState({ expanded: true });
    }
  }

  handleBlur = () => {
    if (this.state.isMobile) {
      this.setState({ expanded: false });
    }
  }

  getPercentage = (taskAcitivities: TaskActivityResData[]) => {
    if(taskAcitivities.length > 0){
      const taskDone = taskAcitivities.filter((task) => task.status === true );
      return Math.round((taskDone.length/taskAcitivities.length)*100);
    }else{
      return 100;
    }
  }

  getStatusLabel = (status: string) => {
    if(status === "pending"){
      return "Pending";
    }else if(status === "in_progress"){
      return "Inprogress";
    }else if(status === "completed"){
      return "Completed"
    }else{
      return "Not Started";
    }
  }

  handleTabData = (label: string, status: string) => {
    this.setState({ selectedTab: status, selectedTabLabel: label })
  }

  getTotalByStatus = (data: TaskDataRes[], status: string) => data.filter((taskDetail) => taskDetail.attributes.status === status).length;

  handleGetTaskData = (webResponseJson: TasksDataRes) => {
    const tasksData: TaskData[] = [];
    if(webResponseJson.data && webResponseJson.data.length > 0){
      webResponseJson.data.forEach((taskDetail) => {
        tasksData.push({
          id: taskDetail.id,
          status: this.getStatusLabel(taskDetail.attributes.status),
          updatedOn: moment(taskDetail.attributes.updated_at).format('MMM DD'),
          title: taskDetail.attributes.title,
          description: taskDetail.attributes.description,
          percentage: this.getPercentage(taskDetail.attributes.task_activities)
        })
      })
      if(this.state.selectedTab === "AllTasks"){
        const {data} = webResponseJson;
        const pending = this.getTotalByStatus(data, "pending");
        const inprogress = this.getTotalByStatus(data, "in_progress"); 
        const notStarted = this.getTotalByStatus(data, "not_started");
        const completed = this.getTotalByStatus(data, "completed");
        this.setState({ tasks: { total: webResponseJson.data.length, pending, inprogress, notStarted, completed }})
      }
    }
    this.setState({ taskData: tasksData })
  }

  getSearchInputWidth = () => {
    if(!this.state.expanded){
      return '44px'
    }else if(this.state.isMobile){
      if(window.innerWidth > 479){
        return '150px'
      }else{
        return 'auto'
      }
    }else{
      return '215px'
    }
  }
  
  getColor = (status: string) => {
    switch(status){
        case "Pending":
            return "#FF0000 ";
        case "Inprogress":
            return "#FD8D02";
        case "Completed":
            return "#019F48"
        case "Not Started":
            return "#9E9E9E";
    }
  }

  getBackColor = (status: string) => {
    switch(status){
      case "Pending": 
        return "#FFCFCF";
      case "Inprogress":
        return "#FFE8CB";
      case "Completed":
        return "#D0E1F1";
      case "Not Started":
        return "#E8E8E8";
    }
  }

  getBackgroundColor = (status: string) => {
    switch(status){
        case "Pending":
            return "#FFF3F3";
        case "Inprogress":
            return "#FFF8EF";
        case "Completed":
            return "#F5FFFB"
        case "Not Started":
            return "#F5F5F5";
    }
  }

  handleStatusTab = (value: string) => {
    this.setState({ selectedTab: value })
  }

  handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => this.setState({ sortBy: event.target.value as string})

  handleDetailView = (id: string) => {
    const navigateToTaskDetailView: Message = new Message(
      getName(MessageEnum.NavigationTaskDetailViewMessage)
    );
    navigateToTaskDetailView.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigateToTaskDetailView.addData(getName(MessageEnum.UrlPathParamMessage), id);
    this.send(navigateToTaskDetailView);
  }

  getAllTaskData = async() => {
    const { token, selectedTab, searchText, sortBy } = this.state;
    let userId;
    if(this.state.roleType === 'Agent'){
      userId  = this.props.navigation.getParam("navigationBarTitleText");
    } else {
      userId = await getStorageData("userId");
    }
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTasksDetailApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTaskList}${'?account_id='+userId}${'&status='+selectedTab.toLowerCase()}${'&search='+searchText}${'&sort_by='+sortBy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLoginPage = () => {
    const taskDetailToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    taskDetailToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(taskDetailToLoginMessage);
  }

  goBackToDashboard = () => {
    const taskDetailToDashboardMessage: Message = new Message(
      getName(MessageEnum.NavigationDashboardMessage)
    );
    taskDetailToDashboardMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(taskDetailToDashboardMessage);
  }
  // Customizable Area End
}